import React from 'react';
import './BioPage.scss'

const BioPage = () => {
    return ( 
        <div className="animate__animated animate__fadeIn">
            <p className="bio-info">
                A computer science student and Jr. Web Developer. Creative, enterprising and productive 
                who never stops learning. Currently working as a software developer in an Argentine company called "Credito Argentino".
            </p>

            <p className="bio-interest">Interests</p>
            <ul className="interests">
                <li>Computer Science</li>
                <li>Design</li>
                <li>Languages</li>
                <li>Sports</li>
                <li>Videogames</li>
            </ul>
        </div>
    )
}

export default BioPage